<template>
    <div>
        <!-- Table Container Card -->
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-card no-body>
            <b-card-body>
                <b-row class="mb2_last_child">

                    <b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <v-select
                            v-model="siteData"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="site_name"
                            :options="sites"
                            placeholder="Project Site"
                            :clearable="false"
                            @input="siteUpdate(); setFilter(); getSensorRecords();"
                        >
                            <template #selected-option="{ site_name }">
                            <span v-if="site_name.length < 23">{{site_name}}</span>
                            <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                            </template>
                        </v-select>
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <v-select
                            v-model="building"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="buildings"
                            placeholder="Select Building"
                            @input="setFilter();"
                            :clearable="true"
                        >
                            <template #selected-option="{ name }">
                                <span v-if="name.length < 23">{{name}}</span>
                                <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                            </template>
                        </v-select>
                    </b-col>

                    <b-col cols="12" md="4" class="float-right mb-md-0 mb-2 mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five ">
                        <b-button title="Refresh" variant="primary" @click="getSensorRecords();">
                            <feather-icon icon="RefreshCwIcon" size="16" class="align-middle text-body"/>
                        </b-button>
                    </b-col>

                </b-row>
            </b-card-body>
        </b-card>

        <div v-if="siteData && building">
            <div v-if="building_data.length > 0">
                <div v-for="(level, index) in building_data" :key="index">
                    <b-card :title="level.level">
                        <b-row>
                            <b-col md="6" v-for="(lvl) in level.locations" :key="lvl._id">
                                <h5>{{lvl.location_name}}</h5> <hr>

                                <b-table sticky-header
                                    ref="refUserListTable"
                                    class="position-relative"
                                    :items="lvl.devices"
                                    responsive
                                    :fields="tableColumns2"
                                    show-empty
                                    empty-text="No matching records found"
                                >

                                    <template #cell(device_name)="items">
                                        <b-link
                                            v-b-tooltip.hover.v-warning
                                            variant="outline-warning"
                                            :to="{ name: 'device-stats', params: { id: items.item._id, device_name: items.item.device_name, device_type: items.item.device_type, device_id: items.item.device_id, site: items.item.site, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                                            v-if="items.item.device_type != 'Toilet Roll Sensor' && items.item.device_type != 'Hand Soap Dispenser Sensor' && items.item.device_type != 'Trash Bin Sensor' && items.item.device_type != 'Other'"
                                        >
                                            {{ items.item.device_name | capitalize}}
                                        </b-link>

                                        <b-link
                                            v-b-tooltip.hover.v-warning
                                            variant="outline-warning"
                                            :to="{ name: 'sensor-stats', params: { device_id: items.item._id, sensor_id: items.item.device_id, sensor_name: items.item.device_name, sensor_type: items.item.device_type, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                                            v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'"
                                        >
                                            {{ items.item.device_name | capitalize}}
                                        </b-link>
                                    </template>
                                    
                                    <template #cell(last_updated)="items">
                                        <p v-if="items.item.last_updated != 'N/A'" class="wordBreak">
                                            {{ items.item.last_updated | dateRangePicker}}
                                        <br>
                                            {{ items.item.last_updated | timeHM}}
                                        </p>
                                        <p v-else>N/A</p>
                                    </template>
                                
                                    <template #cell(value)="items">
                                        <span v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'">
                                        <span v-if="items.item.percentage < 0 || items.item.percentage > 100"> - </span>
                                        <span v-else>{{ items.item.percentage ? Math.round(items.item.percentage) + '%' : '0%'}}</span>
                                        </span>
                                            
                                        <span v-else-if="items.item.device_status == 'Online' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                                        {{ items.item.current_value ? items.item.current_value : '0'}}
                                        </span>
                                        <span v-else-if="items.item.device_status == 'Offline' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                                        {{ items.item.current_value ? items.item.current_value : '0'}}
                                        </span>

                                        <span v-else-if="(items.item.device_type == 'Wetness Floor Sensor')">{{ items.item.wet | capitalize}}</span>
                                        <span v-else-if="(items.item.device_type == 'Cubicle Occupancy Detection')">{{ items.item.door_status | capitalize}}</span>

                                        <span v-else-if="(items.item.device_type == 'Temperature & Humidity Sensor')">T - {{items.item.current_value}} <br> H - {{items.item.current_humidity}}</span>                            
                                    </template>


                                    <template #cell(device_status)="items">

                                        <p v-if="items.item.device_status == 'Error'">
                                            <b-badge pill variant="info" class="text-capitalize w-100">
                                                    Error
                                            </b-badge>
                                        </p>
                                        <p v-if="items.item.device_status == 'Empty'">
                                            <b-badge pill :variant="items.item.device_type == 'Trash Bin Sensor' ? 'success' : 'danger'" class="text-capitalize w-100">
                                                    Empty
                                            </b-badge>
                                        </p>
                                        <p v-if="items.item.device_status == 'Low'">
                                            <b-badge pill variant="warning" class="text-capitalize w-100">
                                                    Low
                                            </b-badge>
                                        </p>
                                        <p v-if="items.item.device_status == 'Mid'">
                                            <b-badge pill style="background: #D4AF37;" class="text-capitalize w-100">
                                                    Mid
                                            </b-badge>
                                        </p>
                                        <p v-if="items.item.device_status == 'Full'">
                                            <b-badge pill :variant="items.item.device_type == 'Trash Bin Sensor' ? 'danger' : 'success'" class="text-capitalize w-100">
                                                    Full
                                            </b-badge>
                                        </p>
                                        <p v-if="items.item.device_status == 'Offline'">
                                            <b-badge pill variant="danger" class="text-capitalize w-100">
                                                    Offline
                                            </b-badge>
                                        </p>
                                        <p v-if="items.item.device_status == 'Online'">
                                            <b-badge pill variant="success" class="text-capitalize w-100">
                                                    Online
                                            </b-badge>
                                        </p>

                                    </template>

                                </b-table>
                                
                            </b-col>
                        </b-row>
                    </b-card>
                </div>
            </div> 
            <div v-else>
                <h5 class="text-center">No Record Found</h5>
            </div>
        </div>

        <div v-else-if="!siteData || !building">
            <div v-for="site in building_data" :key="site._id">
                <div v-if="site.buildings.length > 0">
                    <div md="6" v-for="(build) in site.buildings" :key="build._id">
                        <div v-for="(level, index) in build.levels" :key="index">
                            <b-card :title="site.site_name+' - '+build.building_name+' - '+level.level">
                                <b-row>
                                    <b-col md="6" v-for="(lvl) in level.locations" :key="lvl._id">
                                        <h5>{{lvl.location_name}}</h5> <hr>

                                        <b-table sticky-header
                                            ref="refUserListTable"
                                            class="position-relative"
                                            :items="lvl.devices"
                                            responsive
                                            :fields="tableColumns1"
                                            show-empty
                                            empty-text="No matching records found"
                                        >

                                            <template #cell(device_name)="items">
                                            
                                                <b-link
                                                    v-b-tooltip.hover.v-warning
                                                    variant="outline-warning"
                                                    :to="{ name: 'device-stats', params: { id: items.item._id, device_name: items.item.device_name, device_type: items.item.device_type, device_id: items.item.device_id, site: items.item.site, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                                                    v-if="items.item.device_type != 'Toilet Roll Sensor' && items.item.device_type != 'Hand Soap Dispenser Sensor' && items.item.device_type != 'Trash Bin Sensor' && items.item.device_type != 'Other'"
                                                >
                                                    {{ items.item.device_name | capitalize}}
                                                </b-link>

                                                <b-link
                                                    v-b-tooltip.hover.v-warning
                                                    variant="outline-warning"
                                                    :to="{ name: 'sensor-stats', params: { device_id: items.item._id, sensor_id: items.item.device_id, sensor_name: items.item.device_name, sensor_type: items.item.device_type, color: graphColor(items.item.percentage, items.item.device_type, items.item.device_status)} }"
                                                    v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'"
                                                >
                                                    {{ items.item.device_name | capitalize}}
                                                </b-link>

                                            </template>
                                        
                                            <template #cell(value)="items">
                                            
                                                <span v-if="items.item.device_type == 'Toilet Roll Sensor' || items.item.device_type == 'Hand Soap Dispenser Sensor' || items.item.device_type == 'Trash Bin Sensor' || items.item.device_type == 'Other'">
                                                    <span v-if="items.item.percentage < 0 || items.item.percentage > 100"> - </span>
                                                    <span v-else>{{ items.item.percentage ? Math.round(items.item.percentage) + '%' : '0%'}}</span>
                                                </span>
                                                    
                                                <span v-else-if="items.item.device_status == 'Online' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                                                    {{ items.item.current_value ? items.item.current_value : '0'}}
                                                </span>
                                                <span v-else-if="items.item.device_status == 'Offline' && (items.item.device_type == 'Ammonia Sensor' || items.item.device_type == 'Traffic Counter Sensor')">
                                                    {{ items.item.current_value ? items.item.current_value : '0'}}
                                                </span>

                                                <span v-else-if="(items.item.device_type == 'Wetness Floor Sensor')">{{ items.item.wet | capitalize}}</span>
                                                <span v-else-if="(items.item.device_type == 'Cubicle Occupancy Detection')">{{ items.item.door_status | capitalize}}</span>

                                                <span v-else-if="(items.item.device_type == 'Temperature & Humidity Sensor')">T - {{items.item.current_value}} <br> H - {{items.item.current_humidity}}</span>
                                                
                                            </template>

                                            <template #cell(device_status)="items">

                                                <p v-if="items.item.device_status == 'Error'">
                                                    <b-badge pill variant="info" class="text-capitalize w-100">
                                                        Error
                                                    </b-badge>
                                                </p>
                                                <p v-if="items.item.device_status == 'Empty'">
                                                    <b-badge pill :variant="items.item.device_type == 'Trash Bin Sensor' ? 'success' : 'danger'" class="text-capitalize w-100">
                                                        Empty
                                                    </b-badge>
                                                </p>
                                                <p v-if="items.item.device_status == 'Low'">
                                                    <b-badge pill variant="warning" class="text-capitalize w-100">
                                                        Low
                                                    </b-badge>
                                                </p>
                                                <p v-if="items.item.device_status == 'Mid'">
                                                    <b-badge pill style="background: #D4AF37;" class="text-capitalize w-100">
                                                        Mid
                                                    </b-badge>
                                                </p>
                                                <p v-if="items.item.device_status == 'Full'">
                                                    <b-badge pill :variant="items.item.device_type == 'Trash Bin Sensor' ? 'danger' : 'success'" class="text-capitalize w-100">
                                                        Full
                                                    </b-badge>
                                                </p>
                                                <p v-if="items.item.device_status == 'Offline'">
                                                    <b-badge pill variant="danger" class="text-capitalize w-100">
                                                        Offline
                                                    </b-badge>
                                                </p>
                                                <p v-if="items.item.device_status == 'Online'">
                                                    <b-badge pill variant="success" class="text-capitalize w-100">
                                                        Online
                                                    </b-badge>
                                                </p>

                                            </template>

                                        </b-table>
                                        
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <h5 class="text-center">No Record Found</h5>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script>
import {
    VBTooltip, BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BBreadcrumb
} from 'bootstrap-vue'
import { POST_API } from "../../../store/actions.type"
import vSelect from 'vue-select';
import Bus from "../../../event-bus";

export default {
    components: {
        VBTooltip, vSelect, BFormSelect, BFormSelectOption, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm,BBreadcrumb
    },
    directives: {
        'b-tooltip': VBTooltip,
    },

    data() {
        return {
            webUrl: process.env.VUE_APP_SERVER_URL,
            error_message: null, 
            showDismissibleAlert:false,
            sites: [],
            siteData: null,
            buildings: [],
            building:null,
            levels: [],
            level:'',
            items:[],

            refreshTime: null,

            locations : null,

            tableColumns1: [
                { key: 'device_name', label: 'Sensor', sortable: false , thStyle:  {width: '35%'}},
                { key: 'value', label: 'Current Value', sortable: false , thStyle:  {width: '20%'}},
                { key: 'device_status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},
            ],
            tableColumns2: [
                { key: 'device_id', label: 'Id', sortable: false , thStyle:  {width: '5%'}},
                { key: 'device_name', label: 'Sensor', sortable: false , thStyle:  {width: '35%'}},
                { key: 'value', label: 'Current Value', sortable: false , thStyle:  {width: '20%'}},
                { key: 'device_status', label: 'Status', sortable: false , thStyle:  {width: '10%'}},
                { key: 'last_updated', label: 'Last Updated', sortable: false , thStyle:  {width: '25%'}},
            ],

            building_data: [],

            // vars for filter fetch
                filter: {},
                prev_route: null,
        }
    },

    methods : {
        graphColor(percentage, device_type, status){
            if(device_type == 'Trash Bin Sensor' || device_type == 'Hand Soap Dispenser Sensor' || device_type == 'Toilet Roll Sensor'){
                if(percentage < 0 || percentage > 100){
                    return '00cfe8';
                }
                if(percentage >= 0 && percentage <= 15){
                    return device_type == 'Trash Bin Sensor' ? '28c76f' : 'ea5455';
                }
                if(percentage > 15 && percentage <= 30){
                    return 'ff9f43';
                }
                if(percentage > 30 && percentage <= 75){
                    return 'D4AF37';
                }
                if(percentage > 75){
                    return device_type == 'Trash Bin Sensor' ? 'ea5455' : '28c76f';
                }
            }
            else if(status == 'Offline'){
                return 'ea5455';
            } 
            else if(status == 'Online'){
                return '28c76f';
            }
        },

        allSites(){
            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    om_sites:this.$store.getters.currentUser.om_sites,
                },
                api: '/api/all-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.sites = this.$store.getters.getResults.data;

                    this.siteData = this.defaultSite(this.sites);
                    this.filterState();
                    this.siteUpdate();
                    
                    return this.sites;
                }
            });
        },
        siteUpdate(){
            this.getBuilding();
        },
        

        getBuilding(){
            
            this.buildings = [];
            this.building = null;
            this.levels = [];
            this.level = '';

            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    site:this.siteData ? this.siteData._id : null
                },
                api: '/api/all-buildings-with-level'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.buildings    = this.$store.getters.getResults.data;
                    return this.buildings;
                }
            });
        },

        setFilter(){
            // save filters in local store 
            if(this.siteData != ''){
                this.filter['site'] = this.siteData;
            }
            if(this.building != ''){
                this.filter['building'] = this.building;
                this.getSensorRecords();
            }

            localStorage.setItem("filter_report", JSON.stringify(this.filter));
        },

        fetchLatest(){
            if(this.siteData && this.building){
                this.getBuilding().then(()=>{
                    this.filterState();
                })
            }
            else if(!this.siteData || !this.building){
                Bus.$emit('update-overview');
                this.filterState();
            }
        },
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Smart Sensors',
          },{
            to:null,
            text:'Device Reports'
          }];
          return item;
        },

        refreshData(){
            if(this.$route.name == 'device-report'){
                this.refreshTime = setInterval(() => {
                    this.getSensorRecords();
                }, 10000)
            }
        }, 

        filterState(){
            var filter = JSON.parse(localStorage.getItem("filter_report"));

            if(filter || this.prev_route && (this.prev_route.name == 'device-stats' || this.prev_route.name == 'sensor-stats' || this.prev_route.name == 'device-report')){

                if(this.prev_route && this.prev_route.name != 'smart-toilet' && filter){
                    this.siteData = filter.site ? filter.site : null;
                    this.getBuilding('filter').then(() => {
                        this.building = filter.building ? filter.building : null;
                        this.getSensorRecords();
                    })
                } else {
                    localStorage.setItem("filter_report", JSON.stringify({}));
                    this.getSensorRecords();
                }
            } else {
                localStorage.setItem("filter_report", JSON.stringify({}));
                this.getSensorRecords();
            }
        },

        getSensorRecords(){
            var api  = this.building != null ? '/api/device-report-building' : '/api/device-report'

            return this.$store.dispatch(POST_API, {
                data:{
                    role:this.$store.getters.currentUser.role,
                    site:this.siteData ? this.siteData._id : null,
                    building:this.building ? this.building._id : null
                },
                api: api
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.building_data    = this.$store.getters.getResults.data;
                    return this.building_data;
                }
            });
        }

    },
    mounted(){
        // updating overview data automatically 
        this.refreshData();

        this.allSites();

        this.filterState();
    },

    beforeDestroy () {
        clearInterval(this.refreshTime)
    },
    
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prev_route = from;
        });
    }
}
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
